// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useMemo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';

// ########################## [STATE MANAGEMENT]
import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';

// ########################## [TYPES]
import {
  EntityType,
  VerifyCurrentPasswordMutationPayload,
  VerifyCurrentPasswordMutationResult,
} from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useVerifyCurrentPasswordMutation } from '@shippypro/foundation/settings/api';

// ########################## [UTILS]
import { manageResponse } from '@shippypro/foundation/settings/utils/manage-response';

/**
 * Returns RTK Query hook with renamed data field.
 * Updates one or more profile details based on entity type and entity id.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useVerifyCurrentPassword = (userId: number) => {
  const { t } = useTranslation();

  const [
    fetchMore,
    { data, error, isLoading, isSuccess, isError, ...results },
  ] = useVerifyCurrentPasswordMutation();

  const verifyCurrentPassword = useCallback(
    async (currentPassword: string, entityType?: EntityType) => {
      console.log('verifyCurrentPassword');
      const apiPayload: VerifyCurrentPasswordMutationPayload = {
        entity_type: entityType ?? EntityType.User,
        entity_id: userId,
        password: currentPassword,
      };

      const result = await fetchMore(apiPayload);

      return manageResponse<VerifyCurrentPasswordMutationResult>(result, t);
    },
    [fetchMore, t, userId],
  );

  return useMemo(
    () => ({
      verifyCurrentPassword,
      data,
      unparsedError: error,
      error: parseAPIError(error),
      isLoading,
      isSuccess,
      isError,
      ...results,
    }),
    [
      data,
      error,
      isError,
      isLoading,
      isSuccess,
      results,
      verifyCurrentPassword,
    ],
  );
};
