import { FC } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [STORES]
import { useGetOrderPrevalidationResults } from '@web/features/ship/stores/orders-health-check/orders-health-check-store.selector';

// ########################## [PAGE COMPONENTS]
import { UncontrolledTooltip } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { Order } from '@web/types/order';

interface IPrevalidationBadgeProps {
  order: Order;
  severity: 'warning' | 'error';
}

/**
 * Displays a warning or error badge with a tooltip
 *
 * @author Marcello Aguzzi <marcello.aguzzi@shippypro.com>
 */
export const PrevalidationBadge: FC<IPrevalidationBadgeProps> = ({
  order,
  severity,
}) => {
  const { t } = useTranslation(),
    transPrevalidation = translations.order.prevalidation;

  const badgeTarget = `order-prevalidation-${severity}-${order.id}`;
  const prevalidationResults = useGetOrderPrevalidationResults(
    order.id,
    severity,
  );
  const hasPrevalidationResults =
    !!prevalidationResults && prevalidationResults.length > 0;

  const translatedMessages = prevalidationResults
    ?.map(result => {
      return Object.entries(result)
        .map(([key, value]) => {
          return Object.entries(value).map(([field, rule]) => {
            try {
              return t(transPrevalidation.rules[key][field][rule]);
            } catch (error) {
              return 'MISSING_TRANSLATION';
            }
          });
        })
        .flat();
    })
    .flat()
    .filter(message => message !== 'MISSING_TRANSLATION' && message !== '');
  return (
    hasPrevalidationResults && (
      <>
        <div
          className="w-fit cursor-default flex items-center shrink-0"
          id={badgeTarget}
          data-test={`order-prevalidation-${severity}-badge-${order.id}`}
        >
          <IconHelper
            color={
              severity === 'error'
                ? 'var(--shp-color-genericui-error)'
                : 'var(--shp-color-genericui-warning)'
            }
            icon={
              severity === 'error'
                ? 'IconAlertCircleFilled'
                : 'IconAlertTriangleFilled'
            }
          />
        </div>
        {translatedMessages && (
          <UncontrolledTooltip
            target={badgeTarget}
            placement="top"
            trigger="hover"
            data-test="badge-tooltip"
          >
            {translatedMessages.length === 0 && (
              <span>
                {severity === 'error'
                  ? t(transPrevalidation.generic.error.missingTranslation)
                  : t(transPrevalidation.generic.warning.missingTranslation)}
              </span>
            )}
            {translatedMessages.length === 1 && (
              <span>{translatedMessages[0]}</span>
            )}
            {translatedMessages.length > 1 && (
              <ul className="list-disc list-inside">
                {translatedMessages.map(message => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
            )}
          </UncontrolledTooltip>
        )}
      </>
    )
  );
};
